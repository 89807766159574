import { useSwellWeb3 } from '@/swell-web3/core'
import { UserContext } from './context'

export function useUserApiImpl(): UserContext {
  const { account, provider } = useSwellWeb3()

  return {
    account,
    read: {
      ethBalance: async () => {
        if (!provider) throw new Error('missing provider')
        if (!account) throw new Error('no account')
        return provider.getBalance(account)
      },
    },
  }
}
