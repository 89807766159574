// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/wallet.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AirdropRequest, AirdropResponse, CheckIPRequest, CheckIPResponse, IsGeoRestrictedRequest, IsGeoRestrictedResponse, TokenBalancesRequest, TokenBalancesResponse, TokenMetadataRequest, TokenMetadataResponse } from "./wallet_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.WalletService
 */
export const WalletService = {
  typeName: "swell.v3.WalletService",
  methods: {
    /**
     * @generated from rpc swell.v3.WalletService.Airdrop
     */
    airdrop: {
      name: "Airdrop",
      I: AirdropRequest,
      O: AirdropResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.Idempotent,
    },
    /**
     * @generated from rpc swell.v3.WalletService.CheckIP
     */
    checkIP: {
      name: "CheckIP",
      I: CheckIPRequest,
      O: CheckIPResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.TokenBalances
     */
    tokenBalances: {
      name: "TokenBalances",
      I: TokenBalancesRequest,
      O: TokenBalancesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.TokenMetadata
     */
    tokenMetadata: {
      name: "TokenMetadata",
      I: TokenMetadataRequest,
      O: TokenMetadataResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.IsGeoRestricted
     */
    isGeoRestricted: {
      name: "IsGeoRestricted",
      I: IsGeoRestrictedRequest,
      O: IsGeoRestrictedResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

