import React from 'react'
import styled from 'styled-components/macro'

interface ConnectionDotProps {
  connected?: boolean
  width?: string
  height?: string
  viewBox?: string
  fill?: string
  xmlns?: string
}

const StyledConnectionDot = styled.svg<ConnectionDotProps>`
  ${({ theme, connected }) =>
    !connected &&
    `
    circle {
      fill: ${theme.colors.red['100']}
    }
  `}
`

function ConnectionDot(props: any) {
  return (
    <StyledConnectionDot
      {...props}
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2" cy="2" r="2" fill="#1AFF4B" />
    </StyledConnectionDot>
  )
}

export { ConnectionDot }
