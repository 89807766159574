import { useEthUsdMarketRate } from './hooks'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'

const primaryRefreshIntervalMs = 12_000

export default function FiatUpdater(): null {
  const {
    mutateFallback: ethUsdMarketRateMutate,
    fallbackActive: ethUsdMarketRateFallbackActive,
  } = useEthUsdMarketRate()

  useEveryNthBlock({
    blocksPerFetch: 3,
    fetch: ethUsdMarketRateMutate,
    skip: !ethUsdMarketRateFallbackActive,
  })

  return null
}
