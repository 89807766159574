import { SupportedChainId } from './chains'
import { getAddress } from 'ethers/lib/utils'

export type AddressMap = Record<SupportedChainId, string>

export const CHAINLINK_ETH_USD_FEED_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  [SupportedChainId.GOERLI]: '0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e',
  [SupportedChainId.HARDHAT]: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  [SupportedChainId.SEPOLIA]: '',
  [SupportedChainId.HOLESKY]: '',
}
export const CHAINLINK_WBTC_ETH_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xdeb288F737066589598e9214E782fa5A8eD689e8',
  [SupportedChainId.GOERLI]: '',
  [SupportedChainId.HARDHAT]: '',
  [SupportedChainId.SEPOLIA]: '',
  [SupportedChainId.HOLESKY]: '',
}

export const pDaoAddressMainnet = getAddress(
  '0x20fDF47509C5eFC0e1101e3CE443691781C17F90'
)

export const SWETH_ADDRESSES = {
  swETH: {
    [SupportedChainId.MAINNET]: getAddress(
      '0xf951e335afb289353dc249e82926178eac7ded78'
    ),
  },
  DepositManager: {
    [SupportedChainId.MAINNET]: getAddress(
      '0xb3d9cf8e163bbc840195a97e81f8a34e295b8f39'
    ),
  },
  get WithdrawalAddress() {
    return {
      [SupportedChainId.MAINNET]: this.DepositManager[SupportedChainId.MAINNET],
    }
  },
  NodeOperatorRegistry: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x46ddc39e780088b1b146aba8cbbe15dc321a1a1d'
    ),
  },
  AccessControlManager: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x625087d72c762254a72cb22cc2eca40da6b95eac'
    ),
  },
  ProxyAdmin: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x25eaf579ca2255faa5463c635eec28697b5b8846'
    ),
  },
  RepricingOracle: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x289d600447a74b952ad16f0bd53b8eaaac2d2d71'
    ),
  },
  swEXIT: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x48c11b86807627af70a34662d4865cf854251663'
    ),
  },
}

export const RSWETH_ADDRESSES = {
  rswETH: {
    [SupportedChainId.MAINNET]: getAddress(
      '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0'
    ),
  },
  DepositManager: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x5e6342D8090665bE14eeB8154c8a87B7249a4889'
    ),
  },
  NodeOperatorRegistry: {
    [SupportedChainId.MAINNET]: getAddress(
      '0xAae0B305B3F1edDE7B11b680d4FA9252F7a1c524'
    ),
  },
  AccessControlManager: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x796592b2092F7E150C48643dA19Dd2F28be3333F'
    ),
  },
  ProxyAdmin: {
    [SupportedChainId.MAINNET]: getAddress(
      '0xD750B84845f1cAdfEAc63f96Ec74635e949bFd14'
    ),
  },
  RepricingOracle: {
    [SupportedChainId.MAINNET]: getAddress(
      '0xd5A73c748449a45CC7D9f21c7ed3aB9eB3D2e959'
    ),
  },
  DeprecatedEigenPod: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x8d0B4dfCcc8B2A268486d9754b135d8aD1Ee7258'
    ),
  },
  rswEXIT: {
    [SupportedChainId.MAINNET]: getAddress(
      '0x58749C46Ffe97e4d79508a2C781C440f4756f064'
    ),
  },
}

// EigenLayer
export const EigenLayerDelegationManagerAddress = getAddress(
  '0x39053d51b77dc0d36036fc1fcc8cb819df8ef37a'
)

// Misc
export const MulticallAddress = {
  [SupportedChainId.MAINNET]: getAddress(
    '0xcA11bde05977b3631167028862bE2a173976CA11'
  ),
}
