import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SwellWeb3Provider } from '@swell-web3/core'
import { SwellUiThemeProvider } from '@swell-ui/theme/SwellUiThemeProvider'
import store from '@/state'
import ApplicationUpdater from '@/state/application/updater'
import GasUpdater from '@/state/gas/updater'
import { BlockNumberProvider } from './hooks/useBlockNumber'
import { SWRConfig } from 'swr'
import './i18n'
import '@reach/dialog/styles.css'
import { ENABLE_DEV_GUI } from './configuration/featureFlags'
import WrongChainDetection from './components/WrongChainDetection'
import FiatUpdater from './state/fiat/updater'
import { UserContext } from './state/user/context'
import { useUserApiImpl } from './state/user/api'

const DevGui = lazy(() => import('./components/DevGui'))

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <GasUpdater />
      <FiatUpdater />
    </>
  )
}

function APIProviders({ children }: { children?: any }) {
  const userApi = useUserApiImpl()
  return <UserContext.Provider value={userApi}>{children}</UserContext.Provider>
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SWRConfig>
          <SwellWeb3Provider>
            <BlockNumberProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SwellUiThemeProvider>
                  <APIProviders>
                    <WrongChainDetection />
                    <Updaters />
                    <App />
                    {ENABLE_DEV_GUI && (
                      <Suspense fallback={null}>
                        <DevGui />
                      </Suspense>
                    )}
                  </APIProviders>
                </SwellUiThemeProvider>
              </LocalizationProvider>
            </BlockNumberProvider>
          </SwellWeb3Provider>
        </SWRConfig>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
