/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/constants/chainInfo.ts
 * Changed:
 * - Added hardhat chain info
 * - Removed distinction between L1, L2 chains
 * - Removed chain info items:
 *  - networkType: L1 or L2
 *  - blockWaitMsBeforeWarning: Amount of time to wait before showing a popup that a transaction is taking longer than expected
 *  - docs: doc link
 *  - bridge: Link to bridge for this chain
 *  - infoLink: Link to aggregate uniswap metrics for this chain
 *  - circleLogoUrl: Link to a logo for chain
 *  - helpCenterUrl: Uniswap help center on how to swap on this chain
 *  - color: Color to distinguish chain in UI
 *  - backgroundColor: background color to distinguish chain in UI
 * - defined NativeCurrency interface explicitly
 * - Change name from BaseChainInfo -> ChainInfo (Since there is no longer an inheritance pattern with L1, L2)
 */

import ethereumLogoUrl from '../assets/images/ethereum-logo.png'
import ms from 'ms.macro'
import { SupportedChainId } from './chains'

export const AVERAGE_L1_BLOCK_TIME = ms`12s`

export interface CurrencyMinimal {
  name: string // e.g. 'Goerli ETH',
  symbol: string // e.g. 'gorETH',
  decimals: number // e.g. 18,
}

export interface ChainInfo {
  readonly explorer: string
  readonly logoUrl: string
  readonly label: string
  readonly nativeCurrency: CurrencyMinimal
}

type ChainInfoMap = {
  readonly [chainId: number]: ChainInfo
}

const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.HARDHAT]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.GOERLI]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Goerli',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Goerli Ether', symbol: 'goETH', decimals: 18 },
  },
  [SupportedChainId.SEPOLIA]: {
    explorer: 'https://sepolia.etherscan.io/',
    label: 'Sepolia',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Sepolia Ether', symbol: 'sepETH', decimals: 18 },
  },
  [SupportedChainId.HOLESKY]: {
    explorer: 'https://holesky.etherscan.io/',
    label: 'Holesky',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Holesky Ether', symbol: 'holETH', decimals: 18 },
  },
}

export function getChainInfo(chainId: SupportedChainId): ChainInfo

export function getChainInfo(chainId: SupportedChainId): ChainInfo {
  return CHAIN_INFO[chainId]
}
