import { useCallback } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import useDebounce from './useDebounce'

const useSwitchChain = () => {
  return useCallback(async (connector: any, targetChainId: number) => {
    await connector.switchChain(targetChainId)
  }, [])
}

function useSwitchToDeploymentChain() {
  const { chainId: targetChainId } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()

  const switchChain = useSwitchChain()

  return useCallback(async () => {
    if (!connector) return
    try {
      await switchChain(connector, targetChainId)
    } catch (error: any) {
      console.error('Failed to switch networks', error)
    }
  }, [connector, switchChain, targetChainId])
}

/**
 * Compares the current chainId to the deployment chainId
 * Exposes a callback to switch chains to the deployment chainId (switchToDeploymentChain)
 */
export const useChainDetection = () => {
  const { chainId } = useSwellWeb3()
  const { chainId: deploymentChainId } = useDeploymentSetConfig()

  const debouncedChainId = useDebounce(chainId, 400)
  const switchToDeploymentChain = useSwitchToDeploymentChain()

  return {
    switchToDeploymentChain,
    chainId,
    deploymentChainId,
    isWrongChainId: chainId !== deploymentChainId,
    /**
     * chainId might change rapidly in some circumstances, depending on the wallet provider
     *
     * A debounced chainId is provided to use with side effects which depend on the chainId
     *  being wrong.
     */
    isWrongChainIdDebounced: debouncedChainId !== deploymentChainId,
  }
}

export default useChainDetection
