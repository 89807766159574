/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/state/index.ts
 * Changed:
 * - Removed all slices & related config except application, user, connection
 */
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { load, save } from 'redux-localstorage-simple'

import application from './application/reducer'
import deployments from './deployments/reducer'

const PERSISTED_KEYS: string[] = ['user', 'depositSubmission']

const store = configureStore({
  reducer: { application, deployments },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat(
      save({ states: PERSISTED_KEYS, debounce: 1000 })
    ),
  preloadedState: load({
    states: PERSISTED_KEYS,
    disableWarnings: false,
  }),
})

setupListeners(store.dispatch)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
