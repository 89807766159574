/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/components/Identicon/StatusIcon.tsx
 * Changed:
 * - Removed reference to Uniswap socks NFT
 * - Removed ENS avatar concept; avatar is always default (required contract calls)
 */
import styled from 'styled-components/macro'
import CoinbaseWalletIcon from '@/assets/svg/coinbase-wallet-icon.svg'
import WalletConnectIcon from '@/assets/svg/wallet-connect-icon.svg'
import { Identicon } from '../Identicon'
import { WalletType } from '@swell-web3/types/wallet'
import { SvgFromString } from '@/components/SvgFromString'

export const IconWrapper = styled.div<{ size?: number }>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

function useIcon(walletType: WalletType, fallback?: string) {
  // TODO: need a solution to get user's ENS avatar
  const avatar = null

  if (avatar || walletType === WalletType.METAMASK) {
    return <Identicon size={56} />
  } else if (walletType === WalletType.WALLET_CONNECT) {
    return <img src={WalletConnectIcon} alt="WalletConnect" />
  } else if (walletType === WalletType.COINBASE_WALLET) {
    return <img src={CoinbaseWalletIcon} alt="Coinbase Wallet" />
  } else if (fallback) {
    return <SvgFromString svgString={fallback} width={80} />
  }

  return undefined
}

interface StatusIconProps {
  walletType: WalletType
  size?: number
  fallback?: string
}

function StatusIcon({ walletType, size, fallback }: StatusIconProps) {
  const icon = useIcon(walletType, fallback)
  return <IconWrapper size={size ?? 16}>{icon}</IconWrapper>
}

export { StatusIcon }
