import { useSwellWeb3 } from '@/swell-web3/core'
import useSWRImmutable from 'swr/immutable'
import { useUserApi } from './context'

export function useEthBalance() {
  const { account, chainId } = useSwellWeb3()
  const api = useUserApi()

  return useSWRImmutable(['ethBalance', account, chainId], () =>
    api.read.ethBalance()
  )
}
