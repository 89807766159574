import { WalletInit, createEIP1193Provider } from '@web3-onboard/common'

function okxMobileWallet(): WalletInit {
  return () => {
    return {
      label: 'OKX Mobile',
      getIcon: async () => (await import('./icon.js')).default,
      getInterface: async () => {
        let provider: any
        if ((window as any).okxwallet) {
          provider = createEIP1193Provider((window as any).okxwallet)
        } else {
          window.open('https://www.okx.com/web3', '_blank')
          throw new Error('okx wallet not installed')
        }

        return {
          provider,
        }
      },
      platforms: ['all'],
    }
  }
}

export default okxMobileWallet
