import { createPromiseClient } from '@bufbuild/connect'
import { createConnectTransport } from '@bufbuild/connect-web'
import { V3_BACKEND_SERVICES } from '.'
import { useMemo } from 'react'
import { useProtobufQuery } from '@/hooks/useProtobufQuery'
import { AVERAGE_L1_BLOCK_TIME } from '@/constants/chainInfo'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

export function useV3BackendClient(baseUrl: string) {
  return useMemo(() => {
    const transport = createConnectTransport({
      baseUrl,
      useHttpGet: true,
    })

    const lifi = createPromiseClient(V3_BACKEND_SERVICES.LifiService, transport)

    const rates = createPromiseClient(
      V3_BACKEND_SERVICES.RatesService,
      transport
    )
    const operator = createPromiseClient(
      V3_BACKEND_SERVICES.OperatorService,
      transport
    )
    const preDeposit = createPromiseClient(
      V3_BACKEND_SERVICES.PreDepositService,
      transport
    )
    const staker = createPromiseClient(
      V3_BACKEND_SERVICES.StakerService,
      transport
    )
    const stats = createPromiseClient(
      V3_BACKEND_SERVICES.StatsService,
      transport
    )
    const twitter = createPromiseClient(
      V3_BACKEND_SERVICES.TwitterService,
      transport
    )
    const voyage = createPromiseClient(
      V3_BACKEND_SERVICES.VoyageService,
      transport
    )
    const eigenPoints = createPromiseClient(
      V3_BACKEND_SERVICES.EigenPointsService,
      transport
    )
    const wallet = createPromiseClient(
      V3_BACKEND_SERVICES.WalletService,
      transport
    )
    const paraswap = createPromiseClient(
      V3_BACKEND_SERVICES.ParaswapService,
      transport
    )

    const v3BackendClient = {
      lifi,
      rates,
      operator,
      preDeposit,
      staker,
      stats,
      twitter,
      voyage,
      eigenPoints,
      wallet,
      paraswap,
    }

    return { v3BackendClient }
  }, [baseUrl])
}

export function useGetAllStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)
  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.StatsService,
      method: 'all',
      params: {},
      client: v3BackendClient.stats,
      backendUrl,
    },
    { refreshInterval: AVERAGE_L1_BLOCK_TIME }
  )
}
export function useGetRatesEthUsdV3Backend(
  backendUrl: string,
  {
    refreshInterval,
    paused = false,
  }: {
    refreshInterval?: number
    paused?: boolean
  } = {}
) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'ethUsd',
      params: {},
      client: v3BackendClient.rates,
      backendUrl,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useGetRatesSwEthEthV3Backend({
  refreshInterval,
  paused = false,
}: {
  refreshInterval?: number
  paused?: boolean
} = {}) {
  // LST backend for sourcing swETH information
  const { v3BackendUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendUrl)

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'swethEth',
      params: {},
      client: v3BackendClient.rates,
      backendUrl: v3BackendUrl,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useGetRatesRswEthEthV3Backend({
  refreshInterval,
  paused = false,
}: {
  refreshInterval?: number
  paused?: boolean
} = {}) {
  // LRT backend for sourcing rswETH information
  const { v3BackendUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendUrl)

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'swethEth', // TODO: backend may change to rswethEth
      params: {},
      client: v3BackendClient.rates,
      backendUrl: v3BackendUrl,
    },
    {
      refreshInterval,
      paused,
    }
  )
}
