import { DefaultTheme } from 'styled-components/macro'
import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { typography } from './typography'

const lightTheme: DefaultTheme = createTheme({
  mainBgColor: colors.oceanBlue['100'],
  mainColor: 'black',
  secondaryBgColor: 'white',
  accentBgColor: 'rgb(200, 200, 200)',
  accentColor: 'rgb(100, 100, 100)',

  box: {
    borderColor: `rgb(216, 220, 244)`,
  },

  button: {
    fontSize: typography.body.medium.fontSize,
    lineHeight: typography.body.medium.lineHeight,
    primary: {
      bgColor: colors.lightBlue['100'],
      color: colors.white['50'],
      hoverBgColor: 'rgba(150,150,150, 0.7)',
    },
    contained: {
      primary: {
        bgColor: 'rgb(51, 71, 218)',
        color: 'white',
        hoverBgColor: 'rgb(33, 51, 182)',
        activeBgColor: 'rgb(25, 38, 137)',
      },
      secondary: {
        bgColor: 'white',
        color: 'black',
        hoverColor: 'rgb(150, 150, 150)',
      },
    },
    outlined: {
      primary: {
        bgColor: 'transparent',
        color: 'black',
        hoverColor: 'rgb(100, 100, 100)',
      },
    },
  },

  chip: {
    color: 'rgb(166, 167, 175)',
  },

  colors,

  dialog: {
    bgColor: 'white',
    borderColor: 'white',
  },
})

const darkTheme: DefaultTheme = createTheme({
  mainBgColor: colors.oceanBlue['100'],
  mainColor: colors.white['50'],
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',

  a: {
    color: colors.white['125'],
    hoverColor: colors.white['50'],
  },

  accordionTable: {
    summaryBgColor: colors.oceanBlue['100'],
    expandedBgColor:
      'inear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) ), #051a2b',
  },

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  box: {
    bgColor: colors.oceanBlue['125'],
    borderborder: `1px solid ${colors.auxGrey}`,
    color: colors.white['125'],
    label: {
      color: colors.lightBlue['50'],
    },
  },

  bottomNav: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    selectedColor: colors.white['50'],
  },

  button: {
    accent: {
      bgColor: colors.oceanBlue['125'],
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.lightBlue['100'],
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: '#131B5E',
      disabledColor: colors.white['150'],
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      disabledColor: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.white['125'],
    filledBgColor: 'rgba(241,241,241,0.1)',
    filledColor: colors.white['50'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.white['50'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.white['125'],
    },
  },

  dialog: {
    bgColor: colors.oceanBlue['125'],
    borderColor: colors.lightBlue['50'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
    title: {
      color: colors.white['125'],
    },
  },

  divider: {
    darkColor: colors.auxGrey,
    lightColor: colors.auxGrey,
  },

  drawer: {
    bgColor: colors.oceanBlue['125'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  footer: {
    link: {
      color: colors.lightBlue['50'],
    },
  },

  input: {
    underlineColor: colors.white['50'],
    underlineFocusColor: colors.lightBlue['100'],
    outlined: {
      border: '1px solid rgba(255, 255, 255, 0.05)',
    },
  },

  menu: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    borderColor: '#424557',
  },

  pagination: {
    item: {
      color: colors.white['50'],
      bgColor: colors.lightBlue['100'],
    },
  },

  toast: {
    bgColor: colors.oceanBlue['125'],
    altBgColor: `linear-gradient(180deg, #3068EF 0.29%, #1322AC 167.95%)`,
    messageColor: colors.white['125'],
    border: '0.8px solid #424557',
  },

  table: {
    head: {
      color: colors.lightBlue['50'],
      secondaryColor: colors.white['125'],
    },
    cell: {
      bgColor: colors.oceanBlue['100'],
    },
  },

  tokenList: {
    rowHoverColor: colors.oceanBlue['100'],
  },

  typography,
})

const restakeTheme: DefaultTheme = createTheme({
  mainBgColor: colors.eigenLayer['aliceBlue'],
  mainColor: colors.eigenLayer['federalBlue'],
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',

  a: {
    color: '#180d6880',
    hoverColor: colors.eigenLayer['federalBlue'],
  },

  accordionTable: {
    summaryBgColor: colors.eigenLayer['aliceBlue'],
    expandedBgColor: colors.eigenLayer['aliceBlue'],
  },

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  box: {
    bgColor: colors.white['50'],
    border: 'none',
    color: colors.eigenLayer['federalBlue'],
    label: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  bottomNav: {
    bgColor: colors.white['50'],
    color: colors.white['50'],
    selectedColor: colors.eigenLayer['federalBlue'],
  },

  button: {
    accent: {
      bgColor: 'transparent',
      border: `1px solid ${colors.eigenLayer['federalBlue']}`,
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.eigenLayer['yellow'],
      color: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: 'rgba(255, 188, 1, 0.5)',
      disabledColor: 'rgba(24, 13, 104, 0.5)',
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.eigenLayer['federalBlue'],
      disabledColor: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.eigenLayer['federalBlue'],
    filledBgColor: colors.eigenLayer['aliceBlue'],
    filledColor: colors.eigenLayer['federalBlue'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.eigenLayer['federalBlue'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.lightBlue['100'],
    },
  },

  dialog: {
    bgColor: colors.white['50'],
    borderColor: colors.eigenLayer['federalBlue'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
    title: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  divider: {
    darkColor: colors.eigenLayer['federalBlue'],
    lightColor: colors.eigenLayer['platinum'],
  },

  drawer: {
    bgColor: colors.eigenLayer['aliceBlue'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  footer: {
    link: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  input: {
    underlineColor: colors.eigenLayer['federalBlue'],
    underlineFocusColor: colors.lightBlue['100'],
    outlined: {
      border: `1px solid ${colors.lightBlue['50']}`,
    },
  },

  menu: {
    bgColor: colors.white['50'],
    color: colors.eigenLayer['federalBlue'],
    borderColor: '#424557',
  },

  pagination: {
    item: {
      color: colors.eigenLayer['federalBlue'],
      bgColor: colors.eigenLayer['yellow'],
    },
  },

  table: {
    head: {
      color: colors.lightBlue['100'],
      secondaryColor: colors.lightBlue['100'],
    },
    cell: {
      bgColor: colors.white['50'],
    },
  },

  toast: {
    bgColor: colors.white['50'],
    altBgColor: colors.eigenLayer['yellow'],
    messageColor: colors.eigenLayer['federalBlue'],
    border: `0.8px solid #CEDFFC`,
  },

  tokenList: {
    rowHoverColor: colors.eigenLayer['aliceBlue'],
  },

  typography,
})

export { lightTheme, darkTheme, restakeTheme }
