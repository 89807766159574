import React, { useContext } from 'react'
import { DefaultTheme } from 'styled-components/macro'
import { darkTheme } from './themes'

interface SwellUiThemeContextType {
  theme: DefaultTheme
  toggleTheme: () => void
  themeName: string
}

const initialContext: SwellUiThemeContextType = {
  theme: darkTheme,
  themeName: '',
  toggleTheme: () => {},
}

const SwellUiThemeContext = React.createContext(initialContext)

function useSwellUiTheme() {
  return useContext(SwellUiThemeContext)
}

export { SwellUiThemeContext, useSwellUiTheme }
