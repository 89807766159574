import { createContext, useContext } from 'react'
import { UserApi } from './types'

const MISSING_PROVIDER = Symbol()

export type UserContext = UserApi & {
  account: string | undefined
}

export const UserContext = createContext<UserContext | typeof MISSING_PROVIDER>(
  MISSING_PROVIDER
)

export function useUserApi() {
  const ctx = useContext(UserContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'UserContext hooks must be wrapped in a <UserContext.Provider>'
    )
  }
  return ctx
}
