import React from 'react'

function PowerIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_818_17512)">
        <path
          d="M16 6V15.5"
          stroke="white"
          strokeWidth="1.33"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M22 6.7749C23.9875 8.06833 25.5038 9.96937 26.3229 12.1947C27.142 14.42 27.2201 16.8504 26.5455 19.1237C25.8709 21.3971 24.4798 23.3915 22.5794 24.8098C20.6791 26.2282 18.3713 26.9944 16 26.9944C13.6287 26.9944 11.3209 26.2282 9.42057 24.8098C7.52023 23.3915 6.12909 21.3971 5.4545 19.1237C4.77992 16.8504 4.858 14.42 5.67712 12.1947C6.49624 9.96937 8.01253 8.06833 10 6.7749"
          stroke="white"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_818_17512">
          <rect width="32" height="32" fill="red" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { PowerIcon }
