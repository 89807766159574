import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { ConnectionDot } from '@swell-ui/icons/ConnectionDot'
import { DownCaratIcon } from '@swell-ui/icons/DownCaratIcon'
import { Web3AccountInfoPopover } from '@/components/Web3AccountInfoPopover'
import { shortenAddress } from '@/util/hexStrings'
import { AccountInfoButton } from './AccountInfoButton'

const StyledDownCaratIcon = styled(DownCaratIcon)`
  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`

function EthAccountInfoWidget() {
  const { account, ENSName } = useSwellWeb3()
  const shortenedAccount = shortenAddress(account || '', 4, 4)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleAccountClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const isOpen = !!anchorEl

  return (
    <>
      <AccountInfoButton color="accent" onClick={handleAccountClick}>
        <ConnectionDot connected={!!account} />
        {ENSName || shortenedAccount}
        <StyledDownCaratIcon />
      </AccountInfoButton>
      <Web3AccountInfoPopover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          horizontal: 85,
          vertical: -11,
        }}
      />
    </>
  )
}

export { EthAccountInfoWidget }
