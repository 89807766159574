import React from 'react'
import styled from 'styled-components/macro'
import { Skeleton } from '../Skeleton'

interface AsyncDivProps {
  children: () => React.ReactNode
  loading: boolean
  Container?: React.ComponentType<{ children: any }>
  loader?: () => React.ReactNode
}

const StyledAsyncDiv = styled.div`
  min-width: 80px;
`

function AsyncDiv({
  children,
  loading,
  Container = StyledAsyncDiv,
  loader = () => <Skeleton />,
}: AsyncDivProps) {
  return <Container>{loading ? loader() : children()}</Container>
}

export { AsyncDiv }
