import styled from 'styled-components/macro'
import { Logo } from '@swell-ui/branding/Logo'
import { TopNav } from '@swell-ui/TopNav'
import { Web3NavWidget } from '@/components/Web3NavWidget'
import { TopNavBarLinkStack } from './TopNavBarLinkStack'

const StyledTopNav = styled(TopNav)`
  justify-content: flex-start;
  top: 0;
  left: 0;
  height: 120px;
  padding-left: 36px;
  padding-right: 36px;
  z-index: 10;
`
function TopNavBar({ onlyLogo }: { onlyLogo?: boolean }) {
  const middleComponents = [<TopNavBarLinkStack />]
  const rightComponents = [<Web3NavWidget />]

  return (
    <StyledTopNav
      leftComponent={<Logo />}
      middleComponents={onlyLogo ? [] : middleComponents}
      rightComponents={onlyLogo ? [] : rightComponents}
    />
  )
}

export { TopNavBar }
