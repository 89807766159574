import styled from 'styled-components/macro'

const FlexRow = styled.div<{
  direction?: string
  width?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  gap?: string
  flex?: string
  self?: string
  margin?: string
  maxWidth?: string
  textAlign?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  padding: 0;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  flex: ${({ flex }) => (flex ? `${flex}` : `0 1 auto`)};
  ${({ self }) => self && `align-self: ${self};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ border }) => border && `border: ${border};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`

export { FlexRow }
