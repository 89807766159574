import React from 'react'
import styled, { keyframes } from 'styled-components'
import { pulseKeyframe } from '@swell-ui/keyframes'
import { Route, Routes } from 'react-router-dom'
import { Stack } from '@swell-ui/Stack'
import { Typography } from '@swell-ui/Typography'
import { TopNavLink } from './TopNavLink'

interface LinkStackLink {
  to: string
  label: string | React.ReactNode
}

const PulseText = styled.div`
  animation: ${pulseKeyframe} 3s linear 0s infinite normal;
`

const rootLinks: LinkStackLink[] = [
  {
    to: '/stake',
    label: 'Stake',
  },
  {
    to: '/restake',
    label: 'Restake',
  },
  {
    to: '/earn',
    label: 'Earn',
  },
  {
    to: '/portfolio',
    label: 'Portfolio',
  },
  {
    to: '/voyage',
    label: 'Voyage',
  },
  {
    to: '/swell-l2',
    label: 'Swell L2',
  },
]

const operatorLinks: LinkStackLink[] = [
  {
    to: '/operators/dashboard',
    label: 'Dashboard',
  },
  {
    to: '/operators/validator-keys',
    label: 'Validator keys',
  },
  {
    to: '/operators/all-operators',
    label: 'All operators',
  },
]

const restakingOperatorLinks: LinkStackLink[] = [
  {
    to: '/restaking-operators/dashboard',
    label: 'Dashboard',
  },
  {
    to: '/restaking-operators/validator-keys',
    label: 'Validator keys',
  },
  {
    to: '/restaking-operators/all-operators',
    label: 'All operators',
  },
]

function TopNavLinkStack({ links }: { links: LinkStackLink[] }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {links.map((link: LinkStackLink) => {
        return (
          <TopNavLink key={link.to} to={link.to}>
            <Typography variant="body" size="medium" fstyle="bold">
              {link.label}
            </Typography>
          </TopNavLink>
        )
      })}
    </Stack>
  )
}

function TopNavBarLinkStack() {
  return (
    <Routes>
      <Route
        path="/operators/*"
        element={<TopNavLinkStack links={operatorLinks} />}
      />
      <Route
        path="/restaking-operators/*"
        element={<TopNavLinkStack links={restakingOperatorLinks} />}
      />
      <Route path="/*" element={<TopNavLinkStack links={rootLinks} />} />
    </Routes>
  )
}

export { TopNavBarLinkStack }
