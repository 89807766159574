import React from 'react'
import styled from 'styled-components/macro'
import MuiPopover, { PopoverProps } from '@mui/material/Popover'

const StyledMuiPopover = styled(MuiPopover)`
  .MuiPopover-paper {
    background-color: ${({ theme }) => theme.dialog.bgColor};
    color: ${({ theme }) => theme.mainColor};
    border: 1px solid ${({ theme }) => theme.colors.auxGrey};
    border-radius: 16px;
    box-shadow: none;
  }
`

function Popover(props: PopoverProps) {
  return <StyledMuiPopover {...props} />
}

export { Popover }
export type { PopoverProps }
