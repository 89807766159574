import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { GAS_PRICE_NEEDS_BLOCK_LISTENER, useGasPrice } from './hooks'

export default function GasUpdater(): null {
  const { mutate } = useGasPrice()

  useEveryNthBlock({
    blocksPerFetch: 2,
    fetch: mutate,
    skip: !GAS_PRICE_NEEDS_BLOCK_LISTENER,
  })

  return null
}
