import { useMemo } from 'react'
import {
  useEthUsdMarketRateChainlink,
  useSwEthEthMarketRateOnChain,
} from './util/onChainFiat'
import { useDeploymentSetConfig } from '../deployments/hooks'
import {
  useGetRatesEthUsdV3Backend,
  useGetRatesSwEthEthV3Backend,
} from '@/services/V3BackendService/hooks'

/**
 * Queries the v3-backend rates service for ETH/USD rates, and uses on-chain values
 *  from chainlink as a fallback (redundancy).
 */
export function useEthUsdMarketRateRedundant({
  primaryRefreshIntervalMs,
}: {
  primaryRefreshIntervalMs?: number
} = {}) {
  const { v3BackendUrl } = useDeploymentSetConfig()
  // either backend could provide this service
  const backendQuery = useGetRatesEthUsdV3Backend(v3BackendUrl, {
    refreshInterval: primaryRefreshIntervalMs,
  })

  const fallbackActive = useMemo<boolean>(() => {
    return !!backendQuery.error
  }, [backendQuery.error])

  const fallbackQuery = useEthUsdMarketRateChainlink({ active: fallbackActive })

  const { data, error, isLoading, isValidating } = useMemo(() => {
    if (fallbackActive) {
      return {
        ...fallbackQuery,
        get data() {
          if (!fallbackQuery.data) return undefined
          return { rate: fallbackQuery.data.rate }
        },
      }
    }

    return {
      ...backendQuery,
      get data() {
        if (!backendQuery.data) return undefined
        return { rate: parseFloat(backendQuery.data.rate) }
      },
    }
  }, [backendQuery, fallbackActive, fallbackQuery])

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutateFallback: fallbackQuery.mutate,
    fallbackActive,
  }
}

export const useEthUsdMarketRate = useEthUsdMarketRateRedundant

/**
 * Queries the v3-backend rates service for swETH/ETH rates, and uses on-chain values
 *  from chainlink as a fallback (redundancy).
 *
 * TODO: backend value and on-chain value is stubbed
 */
export function useSwEthEthMarketRateRedundant({
  primaryRefreshIntervalMs,
}: {
  // Refresh interval (ms) of the backend data source
  // (Not applicable when on chain fallback is active)
  primaryRefreshIntervalMs?: number
} = {}) {
  const backendQuery = useGetRatesSwEthEthV3Backend({
    refreshInterval: primaryRefreshIntervalMs,
  })

  const fallbackActive = useMemo<boolean>(() => {
    return !!backendQuery.error
  }, [backendQuery.error])

  const fallbackQuery = useSwEthEthMarketRateOnChain({
    active: fallbackActive,
  })

  const { data, error, isLoading, isValidating } = useMemo(() => {
    if (fallbackActive) {
      return {
        ...fallbackQuery,
        get data() {
          if (!fallbackQuery.data) return undefined
          return { rate: fallbackQuery.data.rate }
        },
      }
    }

    return {
      ...backendQuery,
      get data() {
        if (!backendQuery.data) return undefined
        return { rate: parseFloat(backendQuery.data.rate) }
      },
    }
  }, [backendQuery, fallbackActive, fallbackQuery])

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutateFallback: fallbackQuery.mutate,
    fallbackActive,
  }
}

/**
 * Composes redundant ETH/USD and swETH/ETH rate information to produce
 *  swETH/USD exchange rate information.
 *
 * TODO: construct redundant queries for swETH/USD sources when secondary market
 *  data for this rates is available
 */
export function useSwEthUsdMarketRateRedundant() {
  const ethUsd = useEthUsdMarketRateRedundant()
  const swEthEth = useSwEthEthMarketRateRedundant()

  return {
    get data() {
      if (!ethUsd.data || !swEthEth.data) return undefined
      return { rate: ethUsd.data.rate * swEthEth.data.rate }
    },
    error: ethUsd.error ?? swEthEth.error,
    isLoading: ethUsd.isLoading || swEthEth.isLoading,
    isValidating: ethUsd.isValidating || swEthEth.isValidating,
  }
}
