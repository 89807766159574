import React from 'react'

function EtherscanIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_818_16253)">
        <path
          d="M8.78005 15.9507C8.78003 15.8223 8.80537 15.6951 8.85463 15.5765C8.90389 15.4579 8.97609 15.3502 9.06709 15.2595C9.15808 15.1689 9.26608 15.0972 9.38487 15.0484C9.50366 14.9997 9.63092 14.9749 9.75931 14.9755L11.3828 14.9808C11.6417 14.9808 11.89 15.0837 12.073 15.2668C12.2561 15.4499 12.3589 15.6983 12.3589 15.9572V22.0985C12.5418 22.0443 12.7763 21.9865 13.0333 21.9262C13.2117 21.8842 13.3707 21.7832 13.4845 21.6395C13.5983 21.4957 13.6603 21.3178 13.6603 21.1344V13.5167C13.6603 13.3884 13.6856 13.2614 13.7346 13.1429C13.7837 13.0245 13.8556 12.9168 13.9462 12.8261C14.0368 12.7354 14.1445 12.6635 14.2629 12.6144C14.3813 12.5653 14.5083 12.54 14.6365 12.54H16.2632C16.5221 12.5401 16.7703 12.6429 16.9533 12.8261C17.1364 13.0092 17.2392 13.2575 17.2393 13.5164V20.587C17.2393 20.587 17.6464 20.422 18.0432 20.2545C18.1906 20.1922 18.3163 20.0878 18.4048 19.9544C18.4932 19.821 18.5405 19.6645 18.5406 19.5045V11.0752C18.5406 10.947 18.5658 10.82 18.6149 10.7016C18.6639 10.5831 18.7358 10.4755 18.8264 10.3848C18.9171 10.2941 19.0247 10.2222 19.1431 10.1731C19.2615 10.1241 19.3884 10.0988 19.5166 10.0988H21.1433C21.4022 10.0988 21.6505 10.2017 21.8335 10.3848C22.0166 10.5679 22.1194 10.8163 22.1195 11.0752V18.0162C23.5298 16.9938 24.9592 15.7639 26.0934 14.2852C26.258 14.0706 26.3669 13.8185 26.4104 13.5515C26.4539 13.2845 26.4307 13.0109 26.3428 12.7551C25.5753 10.5176 24.1357 8.57188 22.2208 7.18395C20.3059 5.79602 18.0092 5.03364 15.6448 5.00104C9.26882 4.91536 3.99943 10.1219 4.00006 16.5009C3.99379 18.5194 4.52044 20.5038 5.52673 22.2535C5.66551 22.4928 5.86967 22.6875 6.11524 22.8147C6.3608 22.942 6.63757 22.9965 6.91304 22.9719C7.2208 22.9448 7.60396 22.9065 8.05945 22.853C8.25771 22.8304 8.44075 22.7357 8.5737 22.5868C8.70666 22.438 8.78024 22.2454 8.78044 22.0458V15.9507"
          fill="white"
        />
        <path
          d="M8.74544 25.7998C10.4602 27.0477 12.4866 27.7967 14.6004 27.964C16.7143 28.1313 18.8332 27.7104 20.7227 26.7478C22.6123 25.7852 24.1988 24.3185 25.3068 22.5099C26.4148 20.7013 27.0011 18.6214 27.0008 16.5002C27.0008 16.2354 26.9885 15.9735 26.9708 15.7131C22.7711 21.9787 15.0168 24.9078 8.74536 25.8001"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_818_16253">
          <rect
            width="23"
            height="23"
            fill="white"
            transform="translate(4 5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export { EtherscanIcon }
