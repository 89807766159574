import React from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Container } from '@swell-ui/Container'
import { GlobalNotificationContainer } from '@swell-ui/GlobalNotification'
import { TopNavBar } from '@/components/TopNavBar'
import { Footer } from '@/components/Footer'
import { OceanBackground } from '@/components/OceanBackground'
import { Helmet } from 'react-helmet'
import { Home } from './pages/Home'

const StyledGlobalNotificationContainer = styled(GlobalNotificationContainer)`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const AppContainer = styled(Container)`
  padding-top: 120px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;

  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0;
`

const AppInner = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  flex: 1 1 auto;
  max-width: 1536px;
  padding-left: 36px;
  padding-right: 36px;
  margin: 0 auto;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      padding-left: 16px;
      padding-right: 16px;
    }
  `}
`

function App() {
  return (
    <>
      <Helmet>
        <title>Swell Internal</title>
      </Helmet>
      <div style={{ zIndex: 11, position: 'relative', height: 0 }}>
        <TopNavBar />
      </div>
      <ContentLoaded />
      <OceanBackground />
    </>
  )
}

export default App

function ContentLoaded() {
  return (
    <AppContainer maxWidth="xl">
      <StyledGlobalNotificationContainer />
      <AppInner>
        <Routes>
          <Route index element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AppInner>
      <Routes>
        <Route path="voyage" element={<></>} />
        <Route path="*" element={<Footer />} />
      </Routes>
    </AppContainer>
  )
}
