// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/wallet.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.TokenBalancesRequest
 */
export class TokenBalancesRequest extends Message<TokenBalancesRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<TokenBalancesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalancesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalancesRequest | PlainMessage<TokenBalancesRequest> | undefined, b: TokenBalancesRequest | PlainMessage<TokenBalancesRequest> | undefined): boolean {
    return proto3.util.equals(TokenBalancesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenBalance
 */
export class TokenBalance extends Message<TokenBalance> {
  /**
   * @generated from field: string contract_address = 1;
   */
  contractAddress = "";

  /**
   * @generated from field: string token_balance = 2;
   */
  tokenBalance = "";

  constructor(data?: PartialMessage<TokenBalance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contract_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token_balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalance {
    return new TokenBalance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalance {
    return new TokenBalance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalance {
    return new TokenBalance().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalance | PlainMessage<TokenBalance> | undefined, b: TokenBalance | PlainMessage<TokenBalance> | undefined): boolean {
    return proto3.util.equals(TokenBalance, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenBalancesResponse
 */
export class TokenBalancesResponse extends Message<TokenBalancesResponse> {
  /**
   * @generated from field: repeated swell.v3.TokenBalance token_balances = 1;
   */
  tokenBalances: TokenBalance[] = [];

  constructor(data?: PartialMessage<TokenBalancesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalancesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_balances", kind: "message", T: TokenBalance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalancesResponse | PlainMessage<TokenBalancesResponse> | undefined, b: TokenBalancesResponse | PlainMessage<TokenBalancesResponse> | undefined): boolean {
    return proto3.util.equals(TokenBalancesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenMetadataRequest
 */
export class TokenMetadataRequest extends Message<TokenMetadataRequest> {
  /**
   * @generated from field: string token_address = 1;
   */
  tokenAddress = "";

  constructor(data?: PartialMessage<TokenMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenMetadataRequest | PlainMessage<TokenMetadataRequest> | undefined, b: TokenMetadataRequest | PlainMessage<TokenMetadataRequest> | undefined): boolean {
    return proto3.util.equals(TokenMetadataRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenMetadataResponse
 */
export class TokenMetadataResponse extends Message<TokenMetadataResponse> {
  /**
   * @generated from field: int32 chain_id = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string address = 2;
   */
  address = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string symbol = 4;
   */
  symbol = "";

  /**
   * @generated from field: int32 decimals = 5;
   */
  decimals = 0;

  /**
   * @generated from field: string logo_uri = 6;
   */
  logoUri = "";

  constructor(data?: PartialMessage<TokenMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "logo_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenMetadataResponse | PlainMessage<TokenMetadataResponse> | undefined, b: TokenMetadataResponse | PlainMessage<TokenMetadataResponse> | undefined): boolean {
    return proto3.util.equals(TokenMetadataResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.IsGeoRestrictedRequest
 */
export class IsGeoRestrictedRequest extends Message<IsGeoRestrictedRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  constructor(data?: PartialMessage<IsGeoRestrictedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.IsGeoRestrictedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: IsGeoRestrictedRequest | PlainMessage<IsGeoRestrictedRequest> | undefined, b: IsGeoRestrictedRequest | PlainMessage<IsGeoRestrictedRequest> | undefined): boolean {
    return proto3.util.equals(IsGeoRestrictedRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.IsGeoRestrictedResponse
 */
export class IsGeoRestrictedResponse extends Message<IsGeoRestrictedResponse> {
  /**
   * @generated from field: bool is_restricted = 1;
   */
  isRestricted = false;

  constructor(data?: PartialMessage<IsGeoRestrictedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.IsGeoRestrictedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: IsGeoRestrictedResponse | PlainMessage<IsGeoRestrictedResponse> | undefined, b: IsGeoRestrictedResponse | PlainMessage<IsGeoRestrictedResponse> | undefined): boolean {
    return proto3.util.equals(IsGeoRestrictedResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.CheckIPRequest
 */
export class CheckIPRequest extends Message<CheckIPRequest> {
  /**
   * @generated from field: string ip = 1;
   */
  ip = "";

  constructor(data?: PartialMessage<CheckIPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CheckIPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckIPRequest | PlainMessage<CheckIPRequest> | undefined, b: CheckIPRequest | PlainMessage<CheckIPRequest> | undefined): boolean {
    return proto3.util.equals(CheckIPRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.CheckIPResponse
 */
export class CheckIPResponse extends Message<CheckIPResponse> {
  /**
   * @generated from field: bool is_restricted = 1;
   */
  isRestricted = false;

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * @generated from field: string region = 3;
   */
  region = "";

  /**
   * @generated from field: string ip = 4;
   */
  ip = "";

  /**
   * @generated from field: string timestamp = 5;
   */
  timestamp = "";

  constructor(data?: PartialMessage<CheckIPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CheckIPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckIPResponse | PlainMessage<CheckIPResponse> | undefined, b: CheckIPResponse | PlainMessage<CheckIPResponse> | undefined): boolean {
    return proto3.util.equals(CheckIPResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropRequest
 */
export class AirdropRequest extends Message<AirdropRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<AirdropRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropRequest {
    return new AirdropRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropRequest {
    return new AirdropRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropRequest {
    return new AirdropRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropRequest | PlainMessage<AirdropRequest> | undefined, b: AirdropRequest | PlainMessage<AirdropRequest> | undefined): boolean {
    return proto3.util.equals(AirdropRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropResponse
 */
export class AirdropResponse extends Message<AirdropResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string cumulative_amount = 2;
   */
  cumulativeAmount = "";

  /**
   * @generated from field: string total_amount = 3;
   */
  totalAmount = "";

  /**
   * @generated from field: repeated bytes proofs = 4;
   */
  proofs: Uint8Array[] = [];

  constructor(data?: PartialMessage<AirdropResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cumulative_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "proofs", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropResponse {
    return new AirdropResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropResponse {
    return new AirdropResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropResponse {
    return new AirdropResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropResponse | PlainMessage<AirdropResponse> | undefined, b: AirdropResponse | PlainMessage<AirdropResponse> | undefined): boolean {
    return proto3.util.equals(AirdropResponse, a, b);
  }
}

