import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<any>`
  svg {
    width: ${({ width }) => `${width}px` || 'auto'}};
  }
`

function SvgFromString({
  svgString,
  width,
}: {
  svgString: string
  width?: number
}) {
  const ref = React.useRef<any>()

  React.useEffect(() => {
    if (!ref.current) {
      return
    }
    ref.current.innerHTML = svgString
  }, [svgString])

  return <Wrapper width={width} ref={ref} />
}

export { SvgFromString }
