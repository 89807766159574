import React from 'react'
import styled from 'styled-components/macro'
import Stack from '@mui/material/Stack'

interface TopNavProps {
  leftComponent?: string | React.ReactNode
  middleComponents?: (string | React.ReactNode)[]
  rightComponents?: (string | React.ReactNode)[]
}

const TopNavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  height: 77px;
`

const TopNavSection = styled.div`
  flex-grow: 0;
`

const MiddleSection = styled.div`
  flex-grow: 1;
  padding-left: 93.5px;
`

function TopNav({
  leftComponent,
  middleComponents = [],
  rightComponents = [],
  ...props
}: TopNavProps) {
  return (
    <TopNavWrapper {...props}>
      <TopNavSection>{leftComponent}</TopNavSection>
      <MiddleSection>
        <Stack direction="row" alignItems="center" spacing={2}>
          {middleComponents.map(
            (component: string | React.ReactNode, index: number) => {
              return <div key={index}>{component}</div>
            }
          )}
        </Stack>
      </MiddleSection>
      <TopNavSection>
        <Stack direction="row" alignItems="center" spacing={2}>
          {rightComponents.map(
            (component: string | React.ReactNode, index: number) => {
              return <div key={index}>{component}</div>
            }
          )}
        </Stack>
      </TopNavSection>
    </TopNavWrapper>
  )
}

export { TopNav }
